<template>
  <ciam-page :title-suffix="$t('pageTitles.support')">
    <div class="flex flex-col gap-4">
      <ciam-card v-if="showDedicatedStatusPage.enabled">
        <ciam-card-header>
          <template v-slot:header>
            <div class="wrapperTittle">
              <div>
                {{ $t('support.status.header') }}
              </div>
              <div class="right-title ml-1">
                <a href="https://documentation.cloud-iam.com/references/organization-status-page.html#organization-status-page" data-hover-text="Learn more" class="hover-text" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="15" height="15"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                </a>
              </div>
            </div>
            
            </template>
          <template v-slot:subtitle>{{ $t('support.status.subtitle') }}</template>
        </ciam-card-header>

        <ciam-card-content-lines>
          <ciam-card-content-line v-if="statusPageUrl">
            <template v-slot:label>{{ $t('support.status.pageUrl') }}
              
            </template>
            <template v-slot:content>
              <ciam-link target="_blank" :href="statusPageUrl">{{ statusPageUrl }}</ciam-link>
              <div v-if="!showStatusPageSecret" class="flex flex-row gap-2 items-center">
                <ciam-button @click="showStatusPageSecret = true">
                  <ciam-icon name="fa-eye"></ciam-icon>
                </ciam-button>
                <ciam-text class="credentials-blur">{{ statusPageSecret }}</ciam-text>
              </div>
              <div v-else class="flex flex-row gap-2 items-center">
                <ciam-button @click="showStatusPageSecret = false">
                  <ciam-icon name="fa-eye-slash"></ciam-icon>
                </ciam-button>
                <ciam-clipboard :showIcon="false" title="Copy status page secret">
                  <ciam-text class="credentials">{{ statusPageSecret }}</ciam-text>
                </ciam-clipboard>
              </div>
            </template>
          </ciam-card-content-line>
          
        </ciam-card-content-lines>
      </ciam-card>
      <ciam-card v-if="triggerOnCall.enabled">
        <ciam-card-header>
          <template v-slot:header>
            <div class="wrapperTittle">
              <div>
                {{ $t('support.trigger.title') }}
              </div>
              <div class="right-title ml-1">
                <a href="https://documentation.cloud-iam.com/references/on-call-team.html" data-hover-text="Learn more" class="hover-text" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="15" height="15"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
                </a>
              </div>
            </div>
          </template>
          <template v-slot:subtitle>
            Dedicated ticket center to trigger on-call team on <a href="https://www.cloud-iam.com/en/sla#severity-levels" target="_blank"><u>P1-critical</u></a> incident
            <button data-e2e="trigger-on-call-btn" class="button trigger-btn button--red button--icon cap-font-sans-semibold absolute right-14 bottom-10" @click="triggerOnCallTeam()">
              <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" class="mr-2" viewBox="0 0 512 512" width="1em" height="1em" fill="white">
                 <path d="M216 64c-13.3 0-24 10.7-24 24s10.7 24 24 24h16v33.3C119.6 157.2 32 252.4 32 368H480c0-115.6-87.6-210.8-200-222.7V112h16c13.3 0 24-10.7 24-24s-10.7-24-24-24H256 216zM24 400c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H24z"/>
              </svg>
              {{ $t('support.trigger.button') }}
            </button>
          </template>
        </ciam-card-header>
      </ciam-card>
      <ciam-card>
        <iframe v-if="url" :src="url" frameborder="0" class="iframe"></iframe>
      </ciam-card>
    </div>
  </ciam-page>
</template>

<style lang="scss" scoped>
.iframe {
  width: 100%;
  height: 90vh;
}
</style>

<script>
import TicketService from '@/pages/support/TicketService';
import CiamLink from '@/components/CiamLink';
import OrganizationService from '@/pages/organizations/OrganizationService';
import Maybe from 'data.maybe-fgribreau';
import { pathOr } from 'ramda';
import router, { routeNames } from '@/router';
import CiamClipboard from '@/components/CiamClipboard.vue';
import CiamButton from '@/components/CiamButton.vue'

export default {
  name: 'TicketList',
  components: { CiamLink, CiamClipboard, CiamButton },
  swaggerUI: null,

  data() {
    return {
      loadingPromise: new Promise(() => {}), // loading state by default
      url: null,
      organization: Maybe.Nothing(),
      showStatusPageSecret: false,
      showDedicatedStatusPage: {
        enabled: false,
      },
      triggerOnCall: {
        enabled: false,
      },
    };
  },

  mounted() {
    let loader = this.$loading.show();

    if (this.$route.query.organization_id !== this.$route.params.id) {
      this.$router
        .push({
          ...this.$route,
          params: {
            id: this.$route.query.organization_id,
          },
        })
        .catch(() => {});
    }

    this.loadingPromise = OrganizationService.canAccessDedicatedStatusPage(this.organizationId).then(
      // Need implementation in back,so it is enabled by default for admin users only
      (enabled) => (this.showDedicatedStatusPage.enabled = enabled)
    );

    this.loadingPromise = OrganizationService.canTriggerOnCall(this.organizationId).then(
      (enabled) => (this.triggerOnCall.enabled = enabled)
    );

    this.refreshList();

    TicketService.getUrl()
      .then((response) => {
        this.url = response.url;
      })
      .finally(() => loader.hide());
  },
  computed: {
    isAdmin() {
      return this.$keycloak.hasRealmRole('ADMIN');
    },
    statusPageUrl() {
      return this.organization.map((d) => d.statusPageUrl).getOrElse(null);
    },
    statusPageSecret() {
      return this.organization.map((d) => d.statusPageSecret).getOrElse(null);
    },
    organizationId() {
      return this.$route.query.organization_id;
    },
  },
  methods: {
    triggerOnCallTeam() {
      this.$router.push({ name: routeNames.ReportIncident, query: { organization_id: this.$route.query.organization_id, } })
    },
    async refreshList() {
      const loader = this.$loading.show();
      Promise.all([OrganizationService.get(this.organizationId)])
        .then(([organization]) => {
          this.organization = Maybe.Just(organization);
        })
        .catch((err) => {
          if (pathOr(-1, 'response.status'.split('.'), err) === 404) {
            return;
          }

          this.$log.error("Error while fetching the organization", err);
          this.organization = Maybe.Nothing();
          this.members = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style lang="scss">
.credentials {
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #eeeeee;
  padding: 0.2rem;
  font-family: monospace;
  vertical-align: middle;
}

.credentials-blur {
  @extend .credentials;
  filter: blur(3px);
}

.trigger-btn {
  color: #FFFFFF;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.15px;
  cursor: pointer;
  padding: 18px 30px 18px;
  padding-left: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.10);
  border-radius: 2px;
  transition: all 100ms linear;
    transition-property: all;
  transition-property: color, background-color, box-shadow, transform;
  background-color: #CD2727;
}
.right-title {
  flex: 1;
}
.wrapperTittle {
  display: flex;
}

.hover-text {
    position: relative;
}

.hover-text::after {
    content: attr(data-hover-text); 
    position: relative;
    left: 2%;
    bottom: 55%;
    white-space: nowrap;
    background-color: white;
    color: black;
    padding: 5px;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; 
    z-index: 10;
    font-size: small;
    font-style: italic;
}

.hover-text:hover::after {
    opacity: 1; 
}
</style>
